import React from "react";
import { assets } from "../assets/asset";
const InterSolutionElement = ({ image, title, link, imageRight }) => {
  return (
    <div
      className={
        (imageRight ? "md:flex-row-reverse " : "") +
        "flex justify-center items-center md:gap-32 flex-wrap md:flex-nowrap mb-24 "
      }
    >
      <div className="rounded-3xl bg-black/90 p-3 sm:p-5 border-gray-400 border-4 w-fit">
        <div className="h-0 relative sm:top-[-1.7rem] top-[-1.2rem] left-6 w-fit flex gap-2">
          <div className="h-1 bg-gray-400 w-10 rounded" />
          <div className="h-1 bg-gray-400 w-10 rounded" />
        </div>
        <div>
          <img
            src={assets.interiorDesigns.tabletA}
            alt="Design A"
            className="md:max-h-[55vh]"
          />
        </div>
      </div>
      <div className="text-center min-w-48">
        <p className="xl:text-3xl text-2xl my-3">
          <b>{title.split(" ")[0] + " "}</b>
          {title.split(" ").splice(1, 2).join(" ")}
        </p>
        <a
          href={link}
          className="bg-cyan-400 py-1 w-32 rounded inline-block text-xl text-[#171F47] hover:animate-pulse cursor-pointer"
        >
          Play
        </a>
      </div>
    </div>
  );
};

export default InterSolutionElement;
