import React from "react";
const CostCard = ({ image, title, detail, cost }) => {
  return (
    <>
      <div className="shadow-md shadow-gray-500 rounded-lg overflow-hidden text-center max-w-[19rem]">
        <img src={image} alt="" className="w-full h-40 object-cover"/>
        <div className="p-1">
          <h3 className="font-bold text-3xl my-5">{title}</h3>
          <p className="text-lg font-light">{detail}</p>
          <p className="text-2xl mt-5 font-bold">{cost}</p>
          <p className="text-lg mb-5">Per Sqft</p>
        </div>
      </div>
    </>
  );
};

export default CostCard;
