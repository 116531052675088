import React from "react";
const WhyUsCard = ({ icon, title }) => {
  return (
    <div className="flex flex-col items-center text-center max-w-[20rem]">
      {icon}
      <p className="font-bold text-lg">{title}</p>
    </div>
  );
};

export default WhyUsCard;
