import React from "react";
import { assets } from "../../../assets/asset";
import { CelebrationRounded, Explore, HeadsetMic } from "@mui/icons-material";

const HowWeWork = () => {
  const content = [
    {
      icon: <Explore className="text-blue-700" sx={{ fontSize: "2.2rem" }} />,
      header: "Explore",
      subHeader: "Discover at your convenience on our website:",
      contentList: [
        "Design Aesthetics",
        "Spatial Configurations",
        "Pricing Packages",
      ],
      conclusion: "Enjoy the journey ahead!",
    },
    {
      icon: (
        <HeadsetMic className="text-blue-700" sx={{ fontSize: "2.2rem" }} />
      ),
      header: "Engage",
      subHeader: "Guided by our expert designers",
      contentList: [
        "Instant Layouts",
        "Real-Time Design Options",
        "Same-Day Cost Estimates",
      ],
      conclusion: 'Indulge in the seamless "Tech + Touch" experience.',
    },
    {
      icon: (
        <CelebrationRounded
          className="text-blue-700"
          sx={{ fontSize: "2.2rem" }}
        />
      ),
      header: "Enjoy",
      subHeader: "Experience hassle-free execution with:",
      contentList: [
        "Transparency & Visibility",
        "Clear Milestones & Payments",
        "Quality Assurance",
      ],
      conclusion:
        "Expertise in execution coupled with tech-enabled visibility.",
    },
  ];
  return (
    <section>
      <h2 className="px-[2%] text-center lg:text-4xl text-3xl text-black my-8">
        How We <b>Work</b>
      </h2>
      <div className="flex justify-center gap-16 items-center flex-wrap lg:flex-nowrap mb-24 w-[80%] mx-auto lg:flex-row-reverse">
        <img
          src={assets.costPackage.budget}
          alt="Design A"
          className="h-full rounded-lg"
        />
        <div className="min-w-48">
          {content.map((c, i) => (
            <div className="flex items-start gap-3" key={"content_" + i}>
              <div className="translate-y-1">{c.icon}</div>
              <div>
                <h3 className="text-3xl">{c.header}</h3>
                <p className="mt-3">{c.subHeader}</p>
                <ul className="list-disc ml-6">
                  {c.contentList.map((item, i) => (
                    <li key={"content_list_item_" + i}>{item}</li>
                  ))}
                </ul>
                <p className="mb-4">{c.conclusion}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowWeWork;
