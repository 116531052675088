import React from "react";
import { assets } from "../../../assets/asset";

const Projects = () => {
  const projectImageList = [
    assets.costPackage.premium,
    assets.costPackage.budget,
    assets.costPackage.luxury,
    assets.interiorDesigns.tabletA,
    assets.costPackage.luxury,
    assets.costPackage.budget,
    assets.costPackage.premium,
    assets.costPackage.budget,
    assets.costPackage.luxury,
  ];
  return (
    <section className="bg-primary">
      <h2 className="px-[2%] text-center lg:text-4xl text-3xl text-white md:py-10 py-4 bg-secondary/5">
        <b>Executed</b> projects
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:w-[80%] w-[96%] mx-auto gap-4 place-items-center py-10">
        {projectImageList.map((p, i) => (
          <img
            src={p}
            alt="project"
            key={"project_" + i}
            className={
              (i === 3 || (i === 7 && projectImageList.length >= 8)
                ? "md:col-span-2 md:row-span-2"
                : "") + " rounded-lg w-full"
            }
          />
        ))}
      </div>
    </section>
  );
};

export default Projects;
