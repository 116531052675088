import { Person } from "@mui/icons-material";
import React from "react";
import ResponsiveDrawer from "./ResponsiveDrawer";
import { assets } from "../../assets/asset";

const index = () => {
  const startJourneyHandler = () => {};
  const profileHandler = () => {};
  return (
    <>
      <nav className="lg:px-[10%] px-[2%] h-20 flex items-center justify-between">
        {/* <h1 className="text-xl font-bold self-center justify-self-center"> */}
        <img src={assets.logo} alt="Rennov8" className="h-14 lg:h-16 xl:h-18 mr-5" />
        
        <ul className="hidden lg:flex xl:text-lg text-sm font-normal gap-3">
          <li className="hover:underline">WHY US?</li>
          <li className="hover:underline">HOW WE WORK</li>
          <li className="hover:underline">BECOME A PARTNER</li>
          <li className="hover:underline">BLOGS</li>
          <li className="hover:underline">CONTACT US</li>
        </ul>
        <div className="flex gap-2 items-center justify-center">
          <button
            className="hidden lg:block bg-[#5ECAE7] text-[#171F47] text-lg rounded p-1 px-4 hover:invert-[5%]"
            onClick={startJourneyHandler}
          >
            Start Your Journey
          </button>
          <button
            className="border border-black rounded-full h-8 w-8 hover:invert-[20%] flex justify-center items-center"
            onClick={profileHandler}
          >
            <Person />
          </button>
          <div className="lg:hidden">
            <ResponsiveDrawer />
          </div>
        </div>
      </nav>
    </>
  );
};

export default index;
