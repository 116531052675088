import React from 'react'
import Home from './pages/home'

const App = () => {
  return (
     <Home/>
   )
}

export default App
