import React from "react";
import InterSolutionElement from "../../../components/InterSolutionElement";

const InteriorSolutions = () => {
  const interSolutionList = [
    { title: "Explore your style", imageRight: true },
    { title: "Configure your space", imageRight: false },
  ];
  return (
    <>
      <section className="lg:px-[10%] px-[2%] mx-auto text-black my-20">
        <h2 className="xl:text-5xl lg:text-3xl text-2xl text-center mt-12">
          Innovate Your Building Design
        </h2>
        <h3 className="mb-12 text-center xl:text-xl lg:text-lg">
          Tech, global design, seamless execution – Your ideal building
          trifecta.
        </h3>
        {interSolutionList.map((sol, i) => (
          <InterSolutionElement {...sol} key={"sol" + i} />
        ))}
      </section>
    </>
  );
};

export default InteriorSolutions;
