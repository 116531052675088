import React from "react";
import Footer from "../../components/Footer";
import Nav from "../../components/navbar/index";
import Blogs from "./components/Blogs";
import CostPackages from "./components/CostPackages";
import HeroSection from "./components/HeroSection";
import HowWeWork from "./components/HowWeWork";
import InteriorSolutions from "./components/InteriorSolutions";
import OfficesForEveryone from "./components/OfficesForEveryone";
import Projects from "./components/Projects";
import ReadyToEngage from "./components/ReadyToEngage";
import Welcome from "./components/Welcome";
import WhyUs from "./components/WhyUs";

const Home = () => {
  return (
    <>
      <Nav />
      <HeroSection />
      <InteriorSolutions />
      <CostPackages />
      <WhyUs />
      <Projects />
      {/* <OfficesForEveryone /> */}
      <HowWeWork />
      {/* <Welcome /> */}
      {/* <Blogs /> */}
      <ReadyToEngage />
      <Footer />
    </>
  );
};

export default Home;
