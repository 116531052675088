import React from "react";
import { assets } from "../../../assets/asset";
import CostCard from "../../../components/CostCard";

const CostPackages = () => {
  const costList = [
    {
      image: assets.costPackage.buildingExterior,
      title: "Building Exterior",
      detail: "Elegantly designed offices with full functionality, perfect for a professional and welcoming atmosphere.",
      cost: "Rs 500-1500",
    },
    {
      image: assets.costPackage.exteriorRenovation,
      title: "Exterior Renovation",
      detail: "Transform your space with Instagram-worthy designs, high-quality materials, and impeccable finishes.",
      cost: "Rs 800-2000",
    },
    {
      image: assets.costPackage.facadeUpliftment,
      title: "Facade Upliftment",
      detail: "Elevate your office to world-class standards, reflecting your stature and aspirations.",
      cost: "Rs 300-800",
    },
    {
      image: assets.costPackage.floorExtension,
      title: "Floor Extension",
      detail: "Expand your workspace with aesthetically elegant offices designed for maximum functionality.",
      cost: "Rs 1500-3000",
    },
    {
      image: assets.costPackage.terraceDecoration,
      title: "Terrace Decoration",
      detail: "Enhance your terrace with designs, materials, and finishes that are sure to impress on social media.",
      cost: "Rs 600-2500",
    },
    {
      image: assets.costPackage.landscapingSolutions,
      title: "Landscaping Solutions",
      detail: "Create a world-class office environment that mirrors your stature and fulfills your aspirations.",
      cost: "Rs 450-3000",
    },
    {
      image: assets.costPackage.roofingSolutions,
      title: "Roofing Solutions",
      detail: "Upgrade your office's roof with aesthetically pleasing solutions that maintain full functionality.",
      cost: "Rs 200-1000",
    },
    {
      image: assets.costPackage.energyConservation,
      title: "Energy Conservation",
      detail: "Implement designs and materials that not only look great but also contribute to energy conservation.",
      cost: "Rs 200",
    },
    {
      image: assets.costPackage.rainwaterHarvesting,
      title: "Rainwater Harvesting",
      detail: "Opt for custom-tailored rates for rainwater harvesting, reflecting your commitment to sustainability.",
      cost: "Customized Rates",
    }
];

  return (
    <>
      <h2 className="p-[2%] text-center lg:text-4xl text-3xl text-black">
        <b>Understand</b> cost packages
      </h2>
      <div className="flex 2xl:gap-32 gap-16 justify-center w-[80%] mx-auto my-8 flex-wrap">
        {costList.map((item, i) => (
          <CostCard {...item} key={"costCard_" + i} />
        ))}
      </div>
      <div className="w-fit mx-auto mb-10">
        <a
          href={""}
          className="bg-cyan-500 py-2 w-40 rounded inline-block text-xl text-[#171F47] hover:animate-pulse cursor-pointer text-center my-5"
        >
          Know More
        </a>
      </div>
    </>
  );
};

export default CostPackages;
