import React from "react";
import { assets } from "../../../assets/asset";
import WhyUsCard from "../../../components/WhyUsCard";
import {
  ContentPasteOutlined,
  SportsEsportsOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";

const WhyUs = () => {
  const whyUsList = [
    {
      icon: (
        <SportsEsportsOutlined
          className="text-primary"
          sx={{ fontSize: "4rem" }}
        />
      ),
      title:
        "Discover diverse choices: Extensive products, materials at unbeatable prices.",
    },
    {
      icon: (
        <WidgetsOutlined className="text-primary" sx={{ fontSize: "4rem" }} />
      ),
      title: "Explore a range of products and materials at optimal prices.",
    },
    {
      icon: (
        <ContentPasteOutlined
          className="text-primary"
          sx={{ fontSize: "4rem" }}
        />
      ),
      title:
        "Gain real-time delivery visibility with transparent payment milestones.",
    },
  ];
  return (
    <>
      <div className="flex justify-center bg-primary my-20">
        <div className="bg-black rounded-2xl w-fit my-[-1rem] p-4 border-4 border-gray-400 mx-[2%]">
          <img
            src={assets.costPackage.premium}
            alt=""
            className="max-h-[90vh]"
          />
        </div>
      </div>
      <div className="w-[80%] mx-auto mb-20">
        <h2
          text-center
          className="px-[2%] text-center lg:text-4xl text-3xl text-black"
        >
          Why Choose Us
        </h2>
        <h3 className="mb-12 text-center lg:text-lg">
          Expect a bestowed great office and revel in the exquisite journey.
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 place-items-center mb-10 flex-wrap gap-8">
          {whyUsList.map((item, i) => (
            <WhyUsCard {...item} key={"WhyUs_" + i} />
          ))}
        </div>
        <div className="w-fit mx-auto mb-10">
          <a href="" className="hover:underline text-center mx-auto text-xl">
            Let's Start
          </a>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
