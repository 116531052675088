import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Call,
  Handshake,
  Menu,
  Padding,
  PsychologyAlt,
  Public,
  QuestionMark,
} from "@mui/icons-material";

const ResponsiveDrawer = () => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const providedLinks = [
    { linkText: "Why Us?", linkIcon: <QuestionMark /> },
    { linkText: "How We Work", linkIcon: <PsychologyAlt /> },
    { linkText: "Become A Partner", linkIcon: <Handshake /> },
    { linkText: "Blogs", linkIcon: <Padding /> },
    { linkText: "Contact", linkIcon: <Call /> },
  ];
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const startJourneyHandler = () => {};
  const profileHandler = () => {};
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {providedLinks.map((link, index) => (
          <ListItem key={link.linkText} disablePadding>
            <ListItemButton>
              <ListItemIcon>{link.linkIcon}</ListItemIcon>
              <ListItemText primary={link.linkText} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <ListItem disablePadding>
        <ListItemButton onClick={startJourneyHandler}>
          <ListItemIcon>
            <Public />
          </ListItemIcon>
          <ListItemText primary={"Start your journey"} />
        </ListItemButton>
      </ListItem>
    </Box>
  );

  const anchor = "left";
  return (
    <div>
      <button onClick={toggleDrawer(anchor, true)} className="px-1">
        <Menu className="text-secondary" sx={{ fontSize: "1.8rem" }} />
      </button>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
    </div>
  );
};

export default ResponsiveDrawer;
