import React from "react";
import { assets } from "../../../assets/asset";

const ReadyToEngage = () => {
  const welcomeList = [
    {
      image: assets.costPackage.premium,
      title: "Designers",
    },
    {
      image: assets.costPackage.premium,
      title: "Contractors",
    },
    {
      image: assets.costPackage.premium,
      title: "Product Suppliers",
    },
  ];
  const startYourJourney = () => {};
  return (
    <section className="mt-10 bg-primary/90 md:px-[10%] px-[2%] mx-auto py-10 h-96 flex flex-col justify-center">
      <h2
        text-center
        className="text-center lg:text-4xl text-3xl flex flex-col text-white p-3 my-10"
      >
        Ready to engage?
      </h2>
      <div className="w-fit mx-auto">
        <button
          className="bg-secondary w-44 p-2 rounded text-primary"
          onClick={startYourJourney}
        >
          Start your journey
        </button>
      </div>
    </section>
  );
};

export default ReadyToEngage;
