import { ArrowRightAlt } from "@mui/icons-material";
import React from "react";
import { assets } from "../../../assets/asset";

const HeroSection = () => {
  const ctaHandler = () => {};
  return (
    <section className="lg:px-[10%] px-[2%] py-20 mx-auto bg-primary min-h-[90vh] grid lg:grid-cols-2 grid-cols-1 items-center">
      <div>
        <h1 className="font-bold text-5xl text-white">
          Rejuvenate Your Building
          <span className="text-cyan-400"> Effortless Productivity</span>
        </h1>
        <h2 className="text-cyan-400 text-4xl"> Timeless Style!</h2>
        <button onClick={ctaHandler} className="text-xl text-white my-3 px-1">
          How We Work <ArrowRightAlt />
        </button>
      </div>
      <img src={assets.hero} alt="" />
    </section>
  );
};

export default HeroSection;
