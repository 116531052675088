import tabletA from "./tabletA.gif";
import luxury from "./luxury.jpg";
import budget from "./budget.jpg";
import premium from "./premium.jpg";
import hero from "./hero.jpg";
import logo from "./logo.jpeg";

import buildingExterior from "./cost-packages/building-exterior.jpg"
import exteriorRenovation from "./cost-packages/exterior-renovation.jpg"
import facadeUpliftment from "./cost-packages/facade-upliftment.jpg"
import floorExtension from "./cost-packages/floor-extension.jpg"
import terraceDecoration from "./cost-packages/terrace-decoration.jpg"
import landscapingSolutions from "./cost-packages/landscaping.jpg"
import roofingSolutions from "./cost-packages/roofing.jpg"
import energyConservation from "./cost-packages/energy-conversation.jpg"
import rainwaterHarvesting from "./cost-packages/rain-water-harvesting.jpg"

export const assets = {
  interiorDesigns: {
    tabletA,
  },
  costPackage: {
    luxury,
    budget,
    premium,
    buildingExterior: buildingExterior,
    exteriorRenovation: exteriorRenovation,
    facadeUpliftment: facadeUpliftment,
    floorExtension: floorExtension,
    terraceDecoration: terraceDecoration,
    landscapingSolutions: landscapingSolutions,
    roofingSolutions: roofingSolutions,
    energyConservation: energyConservation,
    rainwaterHarvesting: rainwaterHarvesting
  },
  hero,
  logo
};
